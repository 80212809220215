import React, { Suspense, lazy } from 'react';
import HomeRenderer from '../components/homeRenderer'
import { Timeline } from 'rsuite';
// const HomeRenderer = React.lazy(() => import('../components/homeRenderer'));

function HomeScr(props) {
  return (
    <div
        style={{
          ...styles.fill,
          paddingLeft:'30px',
          // paddingTop:'70px',
          
        }}
      >
          <Timeline>
            <HomeRenderer data={props.data}/>
          </Timeline>
    </div>
  );
}

  const styles = {};
  styles.fill = {
    position: "absolute",
    // width:"calc(100%-400px)",
    width:"100%",
    
    
    // left: 0,
    // right: 0,
    // bottom: 0
  };
  export default HomeScr;