import React, { useState, useCallback } from 'react';



const ConnScr = (props) => {
   
    return (
        <div
        style={{
          ...styles.fill,
        }}
      >
        <div
          className="conndiv"
        >
          <table>
            <tr>
              <td>
              <div
                
              >
              <a href="https://mewe.com/i/jackkwan14" target="_blank" >
                <img  className="connicon"  src={require("../images/logo/mewe.jpg")} width="100%" />
              </a>
              </div>
  
              <p
                style={
                  {
                    fontFamily: "Century Gothic Std, Arial, sans-serif",
                    fontSize:'15px',
                    
                    height:'50px',
                    marginTop:'20px'
                  }
                }
              >Mewe</p>
              
              </td>
              <td>
              <div
           
              >
              <a href="https://www.instagram.com/somethingaboutourkind/" target="_blank" >
                
                <img  className="connicon"  src={require("../images/logo/ig.png")} width="500px" />
              </a> 
              </div>
              <p
                style={
                  {
                    fontFamily: "Century Gothic Std, Arial, sans-serif",
                    fontSize:'15px',
                    
                    height:'50px',
                    marginTop:'20px'
                  }
                }
              >Instagram</p>
              </td></tr>
              {/* <tr><td></td><td>
              <p
                style={
                  {
                    fontFamily: "Century Gothic Std, Arial, sans-serif",
                    fontSize:'15px',
                    
                  }
                }
              >90481709</p>
              </td>
            </tr> */}
  
          </table>
        
      </div>
      </div>
    );
  }
  const styles = {};
  styles.fill = {
    position: "absolute",
    // width:"calc(100%-400px)",
    width:"100%",
    
    
    // left: 0,
    // right: 0,
    // bottom: 0
  };
  export default ConnScr;