import React, {useState, useEffect}  from 'react';
// import {
//     p_portrait,
//     p_outdoor,
//     l_portrait,
//     l_identity,
// } from "./homePhoto";
import {
    Link
  } from "react-router-dom";
import LazyLoad from 'react-lazyload';
import { Timeline} from 'rsuite';
function HomeRenderer(props) {
    const photos = props.data.data
    if(typeof photos !== 'undefined'){
    if('homePhotos' in photos){
    
    return(
        
        <div style={{width:'100%',}}>
            <h4 style={{
                    fontFamily: "Century Gothic Std, Arial, sans-serif",
                    fontSize:'15px',
                    textAlign:'left',
                    paddingTop:'80px',
                    paddingLeft:'2.5%',
                    width:'100%',
                }}>Recent</h4>
                <div>

                    <Timeline.Item key='i2'>
                        <div
                            style={
                            {
                                fontFamily: "Century Gothic Std, Arial, sans-serif",  
                            }
                            }
                        ><Link to="/l_portrait?prev=home" style={{ 
                            textDecoration: 'none',
                        }}>Self-construction - Portrait</Link></div>
                        {
                            photos.homePhotos.l_portrait.map(
                                (homePhoto, i) => (
                                    
                                    <LazyLoad height={400} once scroll key={i}>
                                        <div style={{width:'100%',}}>
                                            <br/>
                                            <img   src={homePhoto.src} width="90%" />       
                                        </div>
                                    </LazyLoad>
                                )
                            )
                        }
                    </Timeline.Item>
                    <Timeline.Item>
                    <div
                        style={
                        {
                            fontFamily: "Century Gothic Std, Arial, sans-serif",
                        }
                        }
                    ><Link to="/outdoor?prev=home" >Production - Outdoor Project</Link></div>
                    {
                        photos.homePhotos.p_outdoor.map(
                            homePhoto => (
                                
                                <LazyLoad height={400} once scroll>
                                    <div style={{width:'100%',}}>
                                        <br/>
                                        <img   src={homePhoto.src} width="90%" />       
                                    </div>
                                </LazyLoad>
                            )
                        )
                    }
                    </Timeline.Item>

                    <Timeline.Item key='i1'>
                        <div
                            style={
                            {
                                fontFamily: "Century Gothic Std, Arial, sans-serif",
                            }
                            }
                        ><Link to="/l_identity?prev=home" >Self-construction - Our Identity</Link></div>
                        {
                            photos.homePhotos.l_identity.map(
                                (homePhoto, i) => (
                                    
                                    <LazyLoad height={400} once scroll key={i}>
                                        <div style={{width:'100%',}}>
                                            <br/>
                                            <img   src={homePhoto.src} width="90%" />       
                                        </div>
                                    </LazyLoad>
                                )
                            )
                        }
                    </Timeline.Item>
                    
                    
                    
                    
                    
                    <Timeline.Item></Timeline.Item>
                </div>
            
        </div>
    )}else{
        return(<div></div>)
      }}else{
        return(<div></div>)
      }
}
export default HomeRenderer;