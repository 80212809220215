import React, { useState, useCallback } from 'react';
import {
  Link
} from "react-router-dom";
import { Grid, Row, Col } from 'rsuite';

const LeiScr = (props) => {
   
    return (
        <div
        style={{
          ...styles.fill,
        }}
      >
        <Grid fluid className="conndiv">
          <Row className="show-grid">
          <Col xs={2}></Col>  
          <Col xs={11}>
            <div> 
              <Link to="/l_identity?prev=self-construction" >
                <img  className="prodicon"  src={require("../images/logo/DSCF1142.jpg")} width="100%" />
              </Link>
            </div>
            <p
              style={
                {
                  fontFamily: "Century Gothic Std, Arial, sans-serif",
                  fontSize:'15px',
                  
                  height:'20px',
                  marginTop:'20px'
                }
              }
            >
              Our Identity
            </p>
          </Col>
          <Col xs={11}>
            <div>
             <Link to="/l_portrait?prev=self-construction" >
                <img  className="prodicon"  src={require("../images/logo/DSCF2796_a.jpg")} width="100%" />
              </Link>
            </div>
            <p
              style={
                {
                  fontFamily: "Century Gothic Std, Arial, sans-serif",
                  fontSize:'15px',
                  
                  height:'20px',
                  marginTop:'20px'
                }
              }
            >
              Portrait
            </p>
          </Col>
          
          <Col xs={0}></Col>
          </Row>
          <br/>
          <Row className="show-grid">
          <Col xs={7}></Col>  
          <Col xs={11} style={{left:'15px'}}>
            <div>
             <Link to="/l_pet?prev=self-construction" >
                <img  className="prodicon"  src={require("../images/logo/DSCF0925.jpg")} width="100%" />
              </Link>
            </div>
            <p
              style={
                {
                  fontFamily: "Century Gothic Std, Arial, sans-serif",
                  fontSize:'15px',
                  
                  height:'20px',
                  marginTop:'20px'
                }
              }
            >
              Pet
            </p>
          </Col>
          <Col xs={7}></Col>
          </Row>
        </Grid>
        
      </div>
    );
  }
  const styles = {};
  styles.fill = {
    position: "absolute",
    // width:"calc(100%-400px)",
    width:"100%",
    
    
    // left: 0,
    // right: 0,
    // bottom: 0
  };
  export default LeiScr;