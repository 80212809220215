import React, {useState, useEffect}  from 'react';
import React_ui_navbar from '../components/reactui_navbar.js';
import {
    useLocation,
  } from "react-router-dom";
const Header = (props) => {
    let location = useLocation();
    var navKey = location.pathname.replace('/','');
    return (
        <div 
          style={
            {
              width:'calc(4% + 100px)',
              textAlign: "right",
              position:"fixed",
              paddingRight:'20px',
              top:"25vh"
            }
          }
          >
            <div
              style={
                {
                  fontFamily: "Century Gothic Std, Arial, sans-serif",
                  height:'50px',
                  paddingLeft:'5px',
                }
              }
              className="jktext"
            >
              JKPHOTOGRAPHY
            </div>
            <React_ui_navbar 
              path={navKey} 
              style={
                {
                  width:'calc(100% + 12px)',
                  textAlign: "right",
                  
                }
              }
            />
            <div
              style={
                {
                  marginTop:'50px',
                  color:'#929297',
                  paddingRight:'10px',
                  fontSize:'10px',
                }
              }
            >
              <p>Copyright © </p><p>All rights reserved.</p>
            </div>
          </div>
    );
  }

  export default Header;