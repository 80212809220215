import React, { useState, useEffect } from 'react';
import { Timeline} from 'rsuite';
import PhotoGrid from './photoGrid.js';
import getPhoto from '../components/photo'
export const ContentBlock = (props) => {
    // const photos = props.photos;
    const year = props.year;
    const title = props.title;
    var result = {}
    return (
      <Timeline.Item>
          <p
              style={
              {
                  fontFamily: "Century Gothic Std, Arial, sans-serif",
              }
              }
          >{year}</p>
          <div
              style={
              {
                  height:'40px'
              }
              }
          >{title}</div>
          <PhotoGrid photos={props.photos}/>
      </Timeline.Item>
    );
    
    
 }


 export const ProductContentBlock = (props) => {
    // const photos = props.photos;
    const year = props.year;
    const title = props.title;
    const href = props.href;
    const iconSrc = props.iconSrc;
    const company = props.company;
    return (
        <Timeline.Item dot={<a href={href} target="_blank"
        ><img src={iconSrc}  /></a>} >
          <p
            style={
              {
                fontFamily: "Century Gothic Std, Arial, sans-serif",
                paddingTop:'5px'
              }
            }
          >{year}</p>
          <div
            style={
              {
                height:'40px'
              }
            }
          ><a href={href} target="_blank" >{company}</a></div>
          <PhotoGrid photos={props.photos}/>
        </Timeline.Item>
    );
 }

