import React, {useState, useEffect}  from 'react';
import { Timeline,Icon, IconButton } from 'rsuite';
import {ContentBlock,ProductContentBlock} from '../components/contentBlock.js';
import {
    Link
  } from "react-router-dom";

const L_PorScr = (props) =>  {
  
  const photos = props.data.data
  if(typeof photos !== 'undefined'){
  if('photos' in photos){
  return (
    <div
    style={{
      ...styles.fill,
      paddingLeft:'20px'
    }}
    >
        <div style={{
        paddingLeft:'50px',
        paddingTop:'80px',
      }}> 
      <div style={{
        // paddingLeft:'50px',
        // paddingTop:'120px',
      }}> 
        <Link to={`/${props.prev}`} >
          <IconButton   icon={<Icon icon='angle-left' width="100%"  />} />
        </Link>
      </div>
      <Timeline>
        <ContentBlock year="2021" title="Lisa" photos={photos.photos['2021_nov_p_1st']} />
        <ContentBlock year="2021" title="Inclass Practice" photos={photos.photos['2021_feb_p_1st']} />
        <ContentBlock year="2020" title="Inclass Practice" photos={photos.photos['2020_nov_p_2nd']} />
        <ContentBlock year="2020" title="Inclass Practice" photos={photos.photos['2020_nov_p_1st']} />
        {/* <ContentBlock year="2017" title="Tokyo" photos={expl_2017_Tokyo} />
        <ContentBlock year="2016" title="Osaka" photos={expl_2016_Osaka} />
        <ContentBlock year="2010" title="Europe" photos={expl_2010_Europe} /> */}
        <Timeline.Item></Timeline.Item>
      </Timeline>
    </div></div>
  );}else{
    return(<div></div>)
  }}else{
    return(<div></div>)
  }
}

  const styles = {};
  styles.fill = {
    position: "absolute",
    width:"100%",
  };
  export default L_PorScr;