import React, {useState, useEffect}  from 'react';
import { Timeline,Icon, IconButton } from 'rsuite';
import {ContentBlock,ProductContentBlock} from '../components/contentBlock.js';
import {
    Link
  } from "react-router-dom";

function ExploreScr(props) {
  const photos = props.data.data
  if(typeof photos !== 'undefined'){
  if('photos' in photos){
  return (
    <div
    style={{
      ...styles.fill,
      paddingLeft:'20px'
    }}
    >
        <div style={{
        paddingLeft:'50px',
        paddingTop:'160px',
      }}> 
      <Timeline>
        <ContentBlock year="2018" title="Fukuoka" photos={photos.photos['2018_1st']} />
        <ContentBlock year="2017" title="Tokyo" photos={photos.photos['2017_1st']} />
        <ContentBlock year="2016" title="Osaka" photos={photos.photos['2016_1st']} />
        <ContentBlock year="2010" title="Europe" photos={photos.photos['2010_1st']} />
        <Timeline.Item></Timeline.Item>
      </Timeline>
    </div></div>
  );}else{
    return(<div></div>)
  }}else{
    return(<div></div>)
  }
}

  const styles = {};
  styles.fill = {
    position: "absolute",
    width:"100%",
  };
  export default ExploreScr;