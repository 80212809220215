const config = {}

config.dev = {
    "domain": "http://192.168.1.29:5765",
}

config.prod = {
    "domain": "https://www.jkphotographyhk.com",
}

const ENV_DEV = false 
const exported = ENV_DEV ? config.dev : config.prod
module.exports  = exported