import React from "react";
import { Nav, Button, Icon, Dropdown, Row, Col, Slider } from 'rsuite';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

const styles = {
    marginBottom: 50
  };
  const NavLink = props => <Nav.Item componentClass={Link} {...props} />;
  const CustomNav = ({ active, onSelect, ...props }) => {
    return (

        <Nav vertical {...props} activeKey={active} onSelect={onSelect} style={
          styles,
          {
            width:"100%",
            margin:'auto',
            textAlign: "right",
            fontSize:'2px',
            wordWrap:'break-word',
            whiteSpace:'normal'
          }
        }>  
          <NavLink eventKey="home" to="/home">Home</NavLink >
            <NavLink 
            eventKey="introduction" 
            // icon={<Icon icon="home" />} 
            to="/introduction">Introduction</NavLink >
            <NavLink eventKey="self-construction" to="/self-construction">Self-construction</NavLink >
            <NavLink eventKey="production" to="/production">Production</NavLink >
            {/* <NavLink eventKey="preservation" to="/preservation" >Preservation</NavLink > */}
            <NavLink eventKey="exploration" to="/exploration">Exploration</NavLink >
            <NavLink eventKey="connection" to="/connection">Connection</NavLink >
        </Nav>
    );
  };
  

  
  class React_ui_navbar extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        active: props.path
      };
      this.handleSelect = this.handleSelect.bind(this);
    }
    handleSelect(activeKey) {
      this.setState({ active: activeKey });
    }
    render() {
      const { active } = this.state;
      return (
        <div>
          <CustomNav appearance="subtle" active={active} onSelect={this.handleSelect} />
        </div>
      );
    }
  }

export default React_ui_navbar;