import React, { useState, useCallback } from 'react';
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-gallery";
import LazyLoad from 'react-lazyload';
import SelectedImage from './SelectedImage';
// const sizeOf = require('image-size')

const PhotoGrid = (props) => {
    const calPhotoRatio=(src)=>{
      const theImage = new Image();
      theImage.src = src;
      let calwidth = 1
      var height = theImage.height;
      var width = theImage.width;
      calwidth = width/height
      return calwidth
    }
    const photos_arr = props.photos
    const photos = photos_arr.map(x=>({
      width:x.width,
      height:1,
      src:x.path
    }));
    // const photos = props.photos
    const imageRenderer = useCallback(
      ({ index, left, top, key, containerHeight, photo }) => (
        <SelectedImage
    
          key={key}
          margin={'2px'}
          index={index}
          photo={photo}
          left={left}
          top={top}
        />
      ),
      [false]
    );

    const openLightbox = useCallback((event, { photo, index }) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };
    

    // photos.map(y => {
    //   console.log(y);
    // });
    return (
      <div
      >
        <LazyLoad height={1000} once scroll>
        <Gallery 
          photos={photos} 
        onClick={openLightbox} 
        // renderImage={imageRenderer} 
        />
        </LazyLoad>
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                frameProps={{ accessibility:true, autoSize: 'height' }}
                views={photos.map(x => ({
                  // width:4,
                  // height:3,
                  src:x.src
                  //...x,
                  // srcset: x.srcSet,
                  // caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
       
      </div>
    );
  }

  export default PhotoGrid;