import React, {useState, useEffect}  from 'react';
import logo from './logo.svg';
import './App.css';
import {useDispatch, useSelector} from 'react-redux'
import {getPhotos} from './actions/repositoryActions'
import MainRouter from './screens/mainRouter.js';
// import Users from './users'
function App() {
  useEffect(() => {
    dispatch(getPhotos()) 
  }, [])
  const dispatch = useDispatch()
  const photosList = useSelector(state => state.photosList)
  return (
    <React.StrictMode>
    <div className="App">
      <MainRouter dataList={photosList.photos} />
    </div>
    </React.StrictMode>
  );
}

export default App;
