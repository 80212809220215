import {GET_PHOTOS, PHOTOS_ERROR} from '../actions/actionTypes'
import axios from "axios";
import configjs from '../data/config'

export const getPhotos = () => async dispatch => {
    
    try{
        const res = await axios.post(
            `${configjs.domain}/10213api/getPhotos`,
            {},
            {credentials: true,}
          )

        dispatch( {
            type: GET_PHOTOS,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: PHOTOS_ERROR,
            payload: console.log(e),
        })
    }

}
