import React, { useState, useCallback } from 'react';



const IntroScr = (props) => {
   
    return (
        <div
        style={{
          ...styles.fill,
        }}
      >
        <div
          className="introdiv"
        >
        <img  className="introme"  src={require("../images/logo/me.jpg")} width="100%" /></div>
        <p
          style={
            {
              fontFamily: "Century Gothic Std, Arial, sans-serif",
              fontSize:'15px',
              
              height:'50px',
              marginTop:'20px'
            }
          }
        >JACK KWAN</p>
        <p
          style={
            {
              fontFamily: "Century Gothic Std, Arial, sans-serif",
              fontSize:'15px',
              height:'30px'
            }
          }
        >A guy who takes photos</p>
        <p
          style={
            {
              fontFamily: "Century Gothic Std, Arial, sans-serif",
              fontSize:'15px',
              
            }
          }
        >and hates endless square frames</p>
      </div>
    );
  }
  const styles = {};
  styles.fill = {
    position: "absolute",
    // width:"calc(100%-400px)",
    width:"100%",
    
    
    // left: 0,
    // right: 0,
    // bottom: 0
  };
  export default IntroScr;