import React, {useState, useEffect}  from 'react';
import { Timeline,Icon, IconButton } from 'rsuite';
import {ContentBlock,ProductContentBlock} from '../components/contentBlock.js';
import {
    Link
  } from "react-router-dom";

function P_PorScr(props) {
  const photos = props.data.data
  if(typeof photos !== 'undefined'){
  if('photos' in photos){
  return (
    <div
    style={{
      ...styles.fill,
      paddingLeft:'20px'
    }}
    className="preservationMain"
    >
      <div style={{
        paddingLeft:'50px',
        paddingTop:'80px',
      }}> 
        <Link to={`/${props.prev}`} >
          <IconButton   icon={<Icon icon='angle-left' width="100%"  />} />
        </Link>
      </div>
      <Timeline className="custom-timeline">
      <ProductContentBlock 
          year="2021" 
          title="Outdoor" 
          photos={photos.photos['2021_p_outdoor']} 
          href="https://www.instagram.com/alfresco.hk/"
          iconSrc={require("../images/logo/alfresco.jpg")}
          company="Al Fresco HK"
        />
        <Timeline.Item></Timeline.Item>
      </Timeline>
    </div>
  );}else{
    return(<div></div>)
  }}else{
    return(<div></div>)
  }
}

  const styles = {};
  styles.fill = {
    position: "absolute",
    width:"100%",
  };
  export default P_PorScr;