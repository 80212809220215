import React, {useState, useEffect}  from 'react';
import '../css/App.css';
import '../css/customTimeline.css';
import { Timeline } from 'rsuite';
import {ContentBlock,ProductContentBlock} from '../components/contentBlock.js';
import HomeScr from '../screens/HomeScr.js';
import IntroScr from '../screens/IntroScr.js';
import ConnScr from '../screens/ConnScr.js';
import ProdScr from '../screens/ProdScr.js';
import LeiScr from '../screens/LeiScr.js';
import L_PorScr from '../screens/l_PorScr.js';
import L_IdenScr from '../screens/l_IdenScr.js';
import L_PetScr from '../screens/l_PetScr.js';
import P_IntScr from '../screens/p_IntScr.js';
import P_PorScr from '../screens/p_PorScr.js';
import P_OutScr from '../screens/p_OutScr.js';
import getPhoto from '../components/photo'
import ExploreScr from '../screens/ExploreScr.js';

import Header from '../components/Header.js';
import { Icon, IconButton } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Perservation from '../components/Perservation.js'
// import ReduxTest from '../reducers/reduxTest.js';
import { useSelector } from 'react-redux';

import LazyLoad from 'react-lazyload';
import {
  expl_2018_Fukuoka,
  expl_2017_Tokyo,
  expl_2016_Osaka,
  expl_2010_Europe,
 } from "../components/photo.js";
import '../css/animation.css';
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";
import {
  Switch,
  Route,
  useLocation,
  Link
} from "react-router-dom";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const MainRouter = (props) => {

    let query = useQuery();
    let location = useLocation();
    var navKey = location.pathname.replace('/','');
    if(navKey == '')
      navKey = 'introduction';
    else if(navKey == 'preservation'){
      // alert(navKey);
    }
    const [photo_base, setPhoto_base] = React.useState([])
    // useEffect(() => {
    //   getPhoto((result) => {
        
    //     // setPhoto_base(result.data)
    //     let data_str = JSON.stringify(result.data)
    //     // console.log(data_str)
    //     localStorage.clear()
    //     localStorage.setItem('photo_data', data_str)
    //   })
    // },[]);
    function l_Portrait() {
  
      return (
        <L_PorScr prev={query.get("prev")} data={props.dataList} />
      );
    }
    function l_Pet() {
      
      return (
        <L_PetScr prev={query.get("prev")} data={props.dataList} />
      );
    }
    function l_Identity() {
      
      return (
        <L_IdenScr prev={query.get("prev")} data={props.dataList} />
      );
    }
    function Portrait() {
  
      return (
    
        <P_PorScr prev={query.get("prev")} data={props.dataList} />
      );
    }
    function Outdoor() {
  
      return (
    
        <P_OutScr prev={query.get("prev")} data={props.dataList} />
      );
    }

    function Interior() {
      
      return (
        <P_IntScr prev={query.get("prev")} data={props.dataList} />
      );
    }

    function Exploration() {
      return (
        <ExploreScr data={props.dataList} />
      );
    }

    function Home() {
      return (
        <HomeScr data={props.dataList}/>
      );
    }
    // const photos = useSelector(state => state.photos);
    // console.log(props.dataList)
    return (
      <div className="App">
        
        
        <div id="container" 
          style={
            {
              maxWidth:"1100px",
              textAlign: "center",
              margin:'auto'
            }
          }
        >
          <Header/>

          <div
            style={{
              ...styles.content,
              
            }}
          >
            <TransitionGroup>
              <CSSTransition
              key={location.key}
              classNames="fade"
              timeout={300}
            >
            
            <Switch location={location}>
              <Route exact path="/" component={Home} />
              <Route exact path="/home" component={Home} />
              <Route exact path="/introduction" component={Introduction} />
              <Route exact path="/self-construction" component={self_construction} />
              <Route exact path="/l_portrait" component={l_Portrait} />
              <Route exact path="/l_identity" component={l_Identity} />
              {/* <Route exact path="/preservation" component={Preservation}/> */}
              <Route exact path="/exploration" component={Exploration}/>
              <Route exact path="/production" component={Production} />
              <Route exact path="/portrait" component={Portrait} />
              <Route exact path="/outdoor" component={Outdoor} />
              <Route exact path="/l_pet" component={l_Pet} />
              <Route exact path="/interior" component={Interior} />
              <Route exact path="/connection" component={Connection}/>
            
            </Switch>
            
            </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      </div>
    );
  }



function Introduction() {
  return (
    <IntroScr/>
  );
}



function Preservation() {
  // return(<ReduxTest/>)
//   return (

//     <div
//     style={{
//       ...styles.fill,
//       paddingLeft:"10px",
//       minHeight:"800px"
//     }}
//     >
//       <Perservation />
    
//     </div>
    
//   );
}

function self_construction() {
  return (
    <LeiScr />
  );
}



function Production() {
  return (
    <ProdScr />
  );
}



function Connection() {
  return (
    <ConnScr/>
  );
}

const styles = {};

styles.fill = {
  position: "absolute",
  width:"100%",
};
styles.content = {
  textAlign: "center",
  maxWidth:'900px',
  width:"calc(100% - 100px)",
  minHeight:'500px',
  height:'80%',
  margin:'auto',
  marginLeft:"calc(4% + 60px)",
  position:"absolute",
  top:"100px"
};

styles.nav = {
  padding: 0,
  margin: 0,
  position: "absolute",
  height: "40px",
  width: "100%",
  display: "flex"
};

styles.navItem = {
  textAlign: "center",
  flex: 1,
  listStyleType: "none",
  padding: "10px"
};


export default MainRouter;