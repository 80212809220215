import React, {useState, useEffect}  from 'react';
import { Timeline,Icon, IconButton } from 'rsuite';
import {ContentBlock,ProductContentBlock} from '../components/contentBlock.js';
import {
    Link
  } from "react-router-dom";

function P_IntScr(props) {
  const photos = props.data.data
  if(typeof photos !== 'undefined'){
  if('photos' in photos){
  return (
    <div
    style={{
      ...styles.fill,
      paddingLeft:'20px',
      
    }}
    // className="preservationMain"
    >
          <div style={{
        paddingLeft:'50px',
        paddingTop:'80px',
      }}>  
        <Link to={`/${props.prev}`} >
        <IconButton   icon={<Icon icon='angle-left' width="100%"  />} />
        </Link>
      </div>
            
      <Timeline className="custom-timeline">
        <ProductContentBlock 
          year="2020" 
          title="Interior Design project" 
          photos={photos.photos['2021_int_1st']} 
          href="https://www.page-interiordesign.com/"
          iconSrc={require("../images/logo/page_design.jpg")}
          company="Page Design"
        />
        <ProductContentBlock 
          year="2020" 
          title="Interior Design project" 
          photos={photos.photos['2020_int_3rd']} 
          href="https://www.page-interiordesign.com/"
          iconSrc={require("../images/logo/page_design.jpg")}
          company="Page Design"
        />
        <ProductContentBlock 
          year="2020" 
          title="Interior Design project" 
          photos={photos.photos['2020_int_2nd']} 
          href="https://www.page-interiordesign.com/"
          iconSrc={require("../images/logo/page_design.jpg")}
          company="Page Design"
        />
        <ProductContentBlock 
          year="2019" 
          title="Interior Design project" 
          photos={photos.photos['2019_int_1st']} 
          href="https://www.minorcreative.com/"
          iconSrc={require("../images/logo/minor_creative.png")}
          company="Minor Creative"
        />
        <Timeline.Item></Timeline.Item>
      </Timeline>
    </div>
  );}else{
    return(<div></div>)
  }}else{
    return(<div></div>)
  }
}

  const styles = {};
  styles.fill = {
    position: "absolute",
    width:"100%",
  };
  export default P_IntScr;