import React, {useState, useEffect}  from 'react';
import { Timeline,Icon, IconButton } from 'rsuite';
import {ContentBlock,ProductContentBlock} from '../components/contentBlock.js';
import {
    Link
  } from "react-router-dom";

function L_PetScr(props) {
  const photos = props.data.data
  if(typeof photos !== 'undefined'){
  if('photos' in photos){
  return (
    <div
    style={{
      ...styles.fill,
      paddingLeft:'20px'
    }}
    >
        <div style={{
        paddingLeft:'50px',
        paddingTop:'80px',
      }}> 
      <div style={{
        // paddingLeft:'50px',
        // paddingTop:'120px',
      }}> 
        <Link to={`/${props.prev}`} >
          <IconButton   icon={<Icon icon='angle-left' width="100%"  />} />
        </Link>
      </div>
      <Timeline>
        <ContentBlock year="2021" title="" photos={photos.photos['2021_pet_1st']} />
        <ContentBlock year="2020" title="" photos={photos.photos['2020_pet_1st']} />
        <Timeline.Item></Timeline.Item>
      </Timeline>
    </div></div>
  );}else{
    return(<div></div>)
  }}else{
    return(<div></div>)
  }
}

  const styles = {};
  styles.fill = {
    position: "absolute",
    width:"100%",
  };
  export default L_PetScr;